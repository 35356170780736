import React, { useContext, useEffect } from 'react';

import { useProfileCompleteness as useProfileCompletenessService } from 'services/profile';

import { useWebSocket } from 'hooks/useWebSocket';
import { useUserSelector } from 'hooks/useUser';

const ProfileCompletenessContext = React.createContext(null);

export function ProfileCompletenessContextProvider({ children }) {
  const { user, reloadUser } = useUserSelector();
  const {
    data = [],
    isLoading,
    refetch
  } = useProfileCompletenessService({
    enabled: !!user?.user_id && !!user?.profile_setup_completed
  });
  const { onDataRefreshMessage } = useWebSocket();

  useEffect(() => {
    onDataRefreshMessage('PROFILE_COMPLETENESS', refetch);
  }, [onDataRefreshMessage, refetch]);

  useEffect(() => {
    onDataRefreshMessage('USER_DATA', reloadUser);
  }, [onDataRefreshMessage, reloadUser]);

  return (
    <ProfileCompletenessContext.Provider
      value={{
        data,
        isLoading,
        reloadProfileCompleteness: refetch
      }}
    >
      {children}
    </ProfileCompletenessContext.Provider>
  );
}

export function useProfileCompleteness() {
  const context = useContext(ProfileCompletenessContext);

  if (!context) {
    throw new Error(
      'useProfileCompleteness must be used within a ProfileCompletenessContextProvider'
    );
  }

  return context;
}
