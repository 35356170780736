import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleInfo,
  faCircleQuestion,
  faCircleExclamation
} from '@fortawesome/free-solid-svg-icons';

import { markAlertAsViewed } from 'services/alerts';

import { useUserSelector } from 'hooks/useUser';
import { useAlerts } from 'hooks/useAlerts';

import ImageComponent from 'ui/Image';
import { Label } from 'ui/Typography';

const Image = styled(ImageComponent)`
  width: 38px;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 22px;
  color: #28c5f1;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: ${(props) => (props.isviewed ? 'var(--grey-10)' : 'var(--white)')};
  padding: 12px 24px;
  cursor: pointer;
  width: 100%;
  align-items: center;
  border-radius: 16px;
`;

const Description = styled.span`
  padding: 0 18px;
  text-align: left;
  width: 100%;
  font-size: 13px;
`;

const ErrorMessage = styled.span`
  font-size: 14px;
`;

function Index({ type, metadata, isViewed, alertId }) {
  const { user } = useUserSelector();
  const { reloadAlerts } = useAlerts();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onClick = useCallback(async () => {
    switch (type) {
      case 'MONEVO':
        switch (metadata?.type) {
          case 'ERROR':
            navigate(
              `/monevo/connect${metadata?.categoryId ? `/${metadata.categoryId}` : ''}${
                metadata?.type === 'ERROR' ? '/fix-error' : ''
              }`
            );
            break;
          case 'REJECTED':
          case 'APPROVED':
          case 'NEW_OFFERS':
            navigate(`/offers/${metadata?.categoryId}/${metadata?.loanPurpose}`);
            break;
          default:
            break;
        }
        break;
      case 'PLAID':
        switch (metadata?.type) {
          case 'CONNECTION_EXPIRED':
          case 'UNEXPECTED_CONNECTION_ERROR':
            navigate('/plaid/connection');
            break;
          case 'BANKING_ACCOUNT_EXPIRED':
            navigate('/settings');
            break;
          default:
            break;
        }
        break;
      case 'EQUIFAX':
        switch (metadata?.type) {
          case 'SUCCESSFULLY_SUBMITTED':
          case 'SUCCESS':
            //navigate('/?focusedKpi=CREDIT_RISK');
            break;
          case 'ERROR':
            navigate('/financial-info/submit/fixEquifaxError');
            break;
          default:
            break;
        }
        break;
      case 'CASHBACKS':
        navigate('/rewards/purchases');
        break;
      case 'REFERRAL_REWARDS':
        navigate('/referral');
        break;
      case 'TEXT':
        break;
      default:
        console.log('Action not implemented = ', type);
        break;
    }

    if (alertId) {
      await markAlertAsViewed(alertId);
      await reloadAlerts();
    }
  }, [type, alertId, metadata, navigate, reloadAlerts]);

  const logo = useMemo(() => {
    switch (type) {
      case 'MONEVO':
        return 'monevo';
      case 'PLAID':
        return 'plaid';
      case 'EQUIFAX':
        return 'equifax_favicon';
      case 'CASHBACKS':
        return 'credit-card';
      case 'REFERRAL_REWARDS':
        return 'credit-card';
      case 'TEXT':
        return 'credit-card';
      case 'PASSPORT':
        return 'passport';
      case 'INFO':
        return null;
      case 'QUESTION':
        return null;
      default:
        return '';
    }
  }, [type]);

  const icon = useMemo(() => {
    switch (type) {
      case 'INFO':
        return faCircleInfo;
      case 'QUESTION':
        return faCircleQuestion;
      default:
        return '';
    }
  }, [type]);

  const title = useMemo(() => {
    switch (type) {
      case 'MONEVO':
        const preferences = user?.preferences?.personal?.values;
        const preference = preferences?.find(({ id }) => id === metadata.categoryId);
        const option = preference?.options?.find(({ selected }) => selected);

        if (!preference && !option) {
          return false;
        }

        if (metadata.type === 'ERROR') {
          return `Failed to get offers for your ${option?.label || preference.label}.`;
        }

        if (metadata.type === 'REJECTED') {
          return `Your ${
            option?.label || preference.label
          } offer was rejected by all lenders. See rejected lenders.`;
        }

        if (metadata.type === 'APPROVED') {
          return `Your first ${
            option?.label || preference.label
          } offers were approved. Check them here.`;
        }

        if (metadata.type === 'NEW_OFFERS' && metadata.count === 1) {
          return `You got ${metadata.count} new ${
            option?.label || preference.label
          } offer. Check it here.`;
        }

        if (metadata.type === 'NEW_OFFERS' && metadata.count > 1) {
          return `You got ${metadata.count} new ${
            option?.label || preference.label
          } offers. Check them here.`;
        }
        break;
      case 'PLAID':
        if (metadata.type === 'CONNECTION_EXPIRED') {
          return 'Your Plaid connection has expired.';
        }
        if (metadata.type === 'UNEXPECTED_CONNECTION_ERROR') {
          return 'Unexpected error occurred, please try again later!';
        }
        if (metadata.type === 'BANKING_ACCOUNT_EXPIRED') {
          return 'Your Plaid banking account connection has expired!';
        }
        if (metadata.type === 'BANKING_ACCOUNT_CONNECTION_IN_PROGRESS') {
          return 'We are currently fetching your banking account data. It will be reflected in your account shortly!';
        }
        break;
      case 'PASSPORT':
        if (metadata.type === 'PASSPORT_OFFER_REQUEST_SUCCESS') {
          return 'Thank you. We will be in touch to coordinate the next steps';
        }
        break;
      case 'EQUIFAX':
        if (metadata.type === 'SUCCESSFULLY_SUBMITTED') {
          return 'Successfully completed Equifax pending task.';
        }
        if (metadata.type === 'SUCCESS') {
          return 'Score received from Equifax.';
        }
        if (metadata.type === 'ERROR') {
          if (metadata.cause === 'ADDRESS_MULTI_MATCH') {
            return 'Multiple matches found with the address provided. Please include all of your address detail including apartment or unit number.';
          } else if (metadata.cause === 'ADDRESS_NO_MATCH') {
            return 'No matches found with the address provided. Please make sure your address is entered correctly.';
          } else if (metadata.cause === 'ACCOUNT_NO_MATCH') {
            return 'No matches found with the account provided. Please make sure your account is entered correctly.';
          } else {
            return 'There was an issue sending your request to Equifax. Please try again later.';
          }
        }
        break;
      case 'CASHBACKS':
        if (metadata.type === 'ERROR') {
          return 'Payment failed. Please try again later.';
        } else if (metadata.type === 'PROCESSING') {
          return 'Payment success. Your gift card is to be issued.';
        } else if (metadata.type === 'ISSUING_FAILED') {
          return 'Due to a third party error, your gift card was not issued and your payment has been refunded. We apologize for any inconvenience this may have caused.';
        } else if (metadata.type === 'ISSUING_ERROR') {
          return `${metadata.brand} failed to issue your gift card. We will retry for the next two hours to have your card issued. If this is unsuccessful, then you will be refunded.`;
        } else if (metadata.type === 'SUCCESS') {
          return `Payment success. Your ${
            metadata?.brand ? metadata?.brand : ''
          } gift card is successfully issued and sent to your email.`;
        }
        break;
      case 'REFERRAL_REWARDS':
        if (metadata.rewardType === 'USER_REGISTER') {
          return (
            <div>
              You received <b>£{metadata.rewardDetails.amount}</b> because{' '}
              <b>{metadata.referredUserEmail}</b> used your referral link to register to Upscore!
            </div>
          );
        } else if (metadata.rewardType === 'PLAID_CONNECTED') {
          if (metadata.referredUserEmail) {
            return (
              <div>
                You received <b>£{metadata.rewardDetails.amount}</b> because{' '}
                <b>{metadata.referredUserEmail}</b> has connected the Upscore account to Plaid!
              </div>
            );
          } else {
            return (
              <div>
                You received <b>£{metadata.rewardDetails.amount}</b> because you have connected your
                account to Plaid!
              </div>
            );
          }
        } else if (metadata.rewardType === 'CREDIT_BUREAU_CONNECTED') {
          return (
            <div>
              You received <b>£{metadata.rewardDetails.amount}</b> because{' '}
              <b>{metadata.referredUserEmail}</b> has connected the Upscore account to Equifax!
            </div>
          );
        }
        break;
      default:
        return metadata?.text;
    }
  }, [type, user, metadata]);

  const errorMessage = useMemo(() => {
    switch (type) {
      case 'MONEVO':
        if (metadata.type === 'ERROR') {
          return 'Your input is required to fix it.';
        }
        break;
      case 'PLAID':
        if (metadata.type === 'CONNECTION_EXPIRED') {
          return 'Your input is required to renew it.';
        }
        if (metadata.type === 'BANKING_ACCOUNT_EXPIRED') {
          return 'Your input is required to reconnect your account.';
        }
        break;
      case 'CASHBACKS':
        if (metadata.type === 'ERROR') {
          return metadata.reason;
        }
        break;
      default:
        return '';
    }
  }, [type, metadata]);

  if (!title) {
    return null;
  }

  return (
    <CardWrapper isviewed={isViewed} onClick={onClick}>
      {logo ? <Image name={logo} /> : null}
      {icon ? <Icon icon={icon} className="fa-regular" /> : null}
      <Description>
        <div>
          <Label variant="label-m">{title}</Label>
        </div>
        {errorMessage ? (
          <ErrorMessage className="text-danger">
            <FontAwesomeIcon icon={faCircleExclamation} className="fa-regular" /> {t(errorMessage)}
          </ErrorMessage>
        ) : null}
      </Description>
    </CardWrapper>
  );
}

export default Index;
