import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const API = `${process.env.REACT_APP_API_BASE_URL}/cashbacks/brands`;
const ORDERS_API = `${process.env.REACT_APP_API_BASE_URL}/cashbacks/orders`;
const ITEMS_API = `${process.env.REACT_APP_API_BASE_URL}/cashbacks/items`;

export const useBrands = () =>
  useQuery({
    queryKey: ['brands'],
    queryFn: async () => {
      const { data } = await axios.get(`${API}`);

      return data;
    }
  });

export const getAllCategories = () => {
  // predefined list by tillo
  // currently we do not have brands of all categories, so we could remove some, but I believe with 800+ brands,
  // we will have all categories present. If not, here we should call rest service that will fetch all distinct categories
  return [
    'baby',
    'beauty',
    'books',
    'cars',
    'charity',
    'craft',
    'cryptocurrency',
    'cycling',
    'department-store',
    'fashion',
    'electronics',
    'food-and-drink',
    'gaming',
    'home',
    'jewellery',
    'music',
    'other',
    'school-vouchers',
    'sports',
    'supermarket',
    'toys',
    'travel-and-leisure',
    'tv-and-movies'
  ];
};

export const getBrandData = async (slug) => {
  const response = await axios.get(`${API}/${slug}`);
  return response.data;
};

export const createOrder = async (slug, amount) => {
  const response = await axios.post(`${ORDERS_API}`, { items: [{ slug, amount }] });
  return response.data;
};

export const usePurchasedCashbacks = ({ enabled }) =>
  useQuery({
    queryKey: ['purchasedCashbacks'],
    queryFn: async () => {
      const { data } = await axios.get(`${ITEMS_API}`);

      return data;
    },
    enabled
  });
