import React, { useContext, useEffect } from 'react';

import { useConnections as useConnectionsService } from 'services/connections';

import { useWebSocket } from 'hooks/useWebSocket';

const ConnectionsContext = React.createContext(null);

export function ConnectionsContextProvider({ children }) {
  const { data = [], refetch, isLoading } = useConnectionsService();
  const { onDataRefreshMessage } = useWebSocket();

  useEffect(() => {
    onDataRefreshMessage('CONNECTIONS', refetch);
  }, [onDataRefreshMessage, refetch]);

  return (
    <ConnectionsContext.Provider
      value={{
        data,
        isLoading,
        reloadConnections: refetch
      }}
    >
      {children}
    </ConnectionsContext.Provider>
  );
}

export function useConnections() {
  const context = useContext(ConnectionsContext);

  if (!context) {
    throw new Error('useConnections must be used within a ConnectionsContextProvider');
  }

  return context;
}
