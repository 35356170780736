import React, { useContext, useMemo, useState } from 'react';

import { MONEVO_CATEGORIES } from 'constants/index';

import { usePersonalData as usePersonalDataService } from 'services/profile';

import { useUserSelector } from 'hooks/useUser';

const UseUserProfile = React.createContext(null);

export function UserProfileSelectorContextProvider({ children }) {
  const { user, isLoading: isUserLoading } = useUserSelector();
  const {
    data: personalData,
    isLoading: isPersonalDataLoading,
    refetch: reloadPersonalData,
    isFetching: isPersonalDataFetching
  } = usePersonalDataService({
    enabled: !!user?.user_id
  });
  const [isSkippingPersonalData, setIsSkippingPersonalData] = useState(false);

  const isUserProfilePreferencesCompleted = useMemo(() => !!user?.preferences, [user]);
  const isOnboardingPersonalDataCompleted = useMemo(
    () => !!user?.first_name && !!user?.last_name && !!user?.dob,
    [user]
  );

  const isUserProfileCompleted = useMemo(() => !!user?.profile_setup_completed, [user]);

  const isPersonalDataCompleted = useMemo(() => {
    const preferences = user?.preferences?.personal?.values;

    if (preferences?.length) {
      if (!preferences.find(({ id }) => MONEVO_CATEGORIES.includes(id))) {
        return true;
      }
      return !!personalData?.dob;
    }

    return !!personalData;
  }, [user, personalData]);

  const onSkipPersonalData = () => {
    setIsSkippingPersonalData(true);
  };

  const isQuickRegisterFlow = useMemo(
    () => !user?.register_type || user.register_type === 'QUICK_REGISTER',
    [user]
  );
  const isOpenBankingRegisterFlow = useMemo(() => !!user?.products?.open_banking, [user]);
  const isOnboardingRegisterFlow = useMemo(() => !!user?.products?.onboarding, [user]);
  const isPendingTasksRegisterFlow = useMemo(() => !!user?.products?.pending_tasks, [user]);
  const isExtendedRegisterFlow = useMemo(
    () => !user?.register_type || user.register_type === 'EXTENDED_REGISTER',
    [user]
  );
  const isBoostYourCreditScoreProfileRegisterFlow = useMemo(
    () => !!user?.products?.boost_your_credit_score,
    [user]
  );
  const isTargetedOffersProduct = useMemo(() => !!user?.products?.targeted_offers, [user]);
  const isReferralProgramRegisterFlow = useMemo(() => !!user?.products?.referral_program, [user]);
  const isBetterDealsProfileRegisterFlow = useMemo(
    () => !user?.products || !!user?.products?.get_better_deals,
    [user]
  );
  const isRealEstateProfileRegisterFlow = useMemo(() => !!user?.products?.real_estate, [user]);

  const isLoading = useMemo(
    () => isUserLoading || isPersonalDataLoading,
    [isUserLoading, isPersonalDataLoading]
  );

  const hasCompletedProfile = useMemo(() => !!personalData?.hasCompletedProfile, [personalData]);
  const hasCompletedBankDetails = useMemo(() => !!personalData?.withdrawal_details, [personalData]);

  const hasOptionToEditProfile = useMemo(() => {
    if (personalData) {
      const { email, hasCompletedProfile, ...rest } = personalData;
      return Object.keys(rest).length > 0;
    }
    return false;
  }, [personalData]);

  return (
    <UseUserProfile.Provider
      value={{
        isLoading,
        isUserProfilePreferencesCompleted,
        isUserProfileCompleted,
        isQuickRegisterFlow,
        isOpenBankingRegisterFlow,
        isPendingTasksRegisterFlow,
        isExtendedRegisterFlow,
        isPersonalDataCompleted,
        isSkippingPersonalData,
        onSkipPersonalData,
        personalData,
        reloadPersonalData,
        hasCompletedProfile,
        hasCompletedBankDetails,
        isPersonalDataLoading,
        isPersonalDataFetching,
        hasOptionToEditProfile,
        isBoostYourCreditScoreProfileRegisterFlow,
        isBetterDealsProfileRegisterFlow,
        isRealEstateProfileRegisterFlow,
        isReferralProgramRegisterFlow,
        isOnboardingRegisterFlow,
        isOnboardingPersonalDataCompleted,
        isTargetedOffersProduct
      }}
    >
      {children}
    </UseUserProfile.Provider>
  );
}

export function useUserProfileSelector() {
  const context = useContext(UseUserProfile);

  if (!context) {
    throw new Error(
      'useUserProfileSelector must be used within a UserProfileSelectorContextProvider'
    );
  }

  return context;
}
