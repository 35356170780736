import React, { useContext, useEffect, useMemo } from 'react';

import { useOffersCategories as useOffersCategoriesService } from 'services/offers';

import { useWebSocket } from 'hooks/useWebSocket';
import { useAlerts } from 'hooks/useAlerts';
import { useFlags } from './useFlags';

const OffersCategoriesContext = React.createContext(null);

export function OffersCategoriesContextProvider({ children }) {
  const { mortgageOfferDynamicDistribution } = useFlags();
  const { data: offersCategories = [], isLoading, refetch } = useOffersCategoriesService();
  const { alerts } = useAlerts();

  const { onDataRefreshMessage } = useWebSocket();

  useEffect(() => {
    onDataRefreshMessage('OFFERS_NEW', refetch);
  }, [onDataRefreshMessage, refetch]);

  const offersCategoriesWithAlerts = useMemo(() => {
    const items = [];

    offersCategories
      .map((offerCategory) => {
        const { logos } = offerCategory;

        offerCategory.logos = logos?.filter((logo) => {
          if (logo === 'molo_logo' && mortgageOfferDynamicDistribution === 'HABITO') {
            return false;
          } else if (logo === 'habito_logo' && mortgageOfferDynamicDistribution === 'MOLO') {
            return false;
          } else {
            return true;
          }
        });

        return offerCategory;
      })
      .forEach((offerCategory) => {
        const { id, monevoLoanPurpose } = offerCategory;

        const lastAlert = alerts.find(({ type, metadata }) => {
          if (type === 'MONEVO') {
            return metadata?.loanPurpose === monevoLoanPurpose && metadata?.categoryId === id;
          }

          return false;
        });

        if (lastAlert && !lastAlert.is_viewed) {
          items.push({
            ...offerCategory,
            lastAlert
          });
        } else {
          items.push(offerCategory);
        }

        return offerCategory;
      });

    return items;
  }, [alerts, offersCategories, mortgageOfferDynamicDistribution]);

  return (
    <OffersCategoriesContext.Provider
      value={{
        data: offersCategoriesWithAlerts,
        isLoading,
        reloadOffers: refetch
      }}
    >
      {children}
    </OffersCategoriesContext.Provider>
  );
}

export function useOffersCategories() {
  const context = useContext(OffersCategoriesContext);

  if (!context) {
    throw new Error('useOffersCategories must be used within a OffersCategoriesContextProvider');
  }

  return context;
}
