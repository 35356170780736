import { useEffect } from 'react';
import { useWebSocket } from 'hooks/useWebSocket';
import { useTracking } from 'hooks/useTracking';

const useServerSideEvents = () => {
  const { onServerSideEvent } = useWebSocket();
  const { trackCustomEvent } = useTracking();

  useEffect(() => {
    onServerSideEvent((topic, payload) => {
      trackCustomEvent({
        topic,
        payload
      });
    });
  }, [onServerSideEvent, trackCustomEvent]);
};

export default useServerSideEvents;
