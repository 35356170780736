import React, { useContext, useCallback } from 'react';
import ReactPixel from 'react-facebook-pixel';

const FacebookPixelContext = React.createContext(null);
const { REACT_APP_ENV, REACT_APP_FB_PIXEL_ID } = process.env;

if (REACT_APP_ENV === 'prod') {
  ReactPixel.init(
    REACT_APP_FB_PIXEL_ID,
    {},
    {
      autoConfig: true,
      debug: true
    }
  );
}

export function FacebookPixelContextProvider({ children }) {
  const devIdentify = useCallback((...data) => console.log('FBP.identify', ...data), []);
  const devTrackPage = useCallback((...data) => console.log('FBP.trackPage', ...data), []);
  const devCustomEvent = useCallback((...data) => console.log('FBP.customEvent', ...data), []);

  if (REACT_APP_ENV !== 'prod') {
    return (
      <FacebookPixelContext.Provider
        value={{
          identify: devIdentify,
          trackPage: devTrackPage,
          trackCustomEvent: devCustomEvent
        }}
      >
        {children}
      </FacebookPixelContext.Provider>
    );
  }

  return (
    <FacebookPixelContext.Provider
      value={{
        identify: (user) => ReactPixel.trackCustom('IDENTIFY', user),
        trackPage: ReactPixel.pageView,
        trackCustomEvent: ReactPixel.trackCustom
      }}
    >
      {children}
    </FacebookPixelContext.Provider>
  );
}

export function useFacebookPixel() {
  const context = useContext(FacebookPixelContext);

  if (!context) {
    throw new Error('useFacebookPixel must be used within a FacebookPixelContext');
  }

  return context;
}
