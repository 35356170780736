import React, { useContext, useEffect, useMemo, useState, useCallback } from 'react';

import { useAlerts as useAlertsService } from 'services/alerts';

import { useWebSocket } from 'hooks/useWebSocket';
import { useUserSelector } from 'hooks/useUser';

const AlertsContext = React.createContext(null);

export function AlertsContextProvider({ children }) {
  const { user } = useUserSelector();
  const {
    data: alerts = [],
    isLoading: isAlertsLoading,
    refetch: reloadAlerts
  } = useAlertsService({
    enabled: !!user?.user_id && !!user?.profile_setup_completed
  });
  const { onDataRefreshMessage } = useWebSocket();
  const [mockedAlerts, setMockedAlerts] = useState([]);

  useEffect(() => {
    onDataRefreshMessage('ALERTS', reloadAlerts);
  }, [onDataRefreshMessage, reloadAlerts]);

  const unreadAlertsCount = useMemo(
    () => !!alerts.filter(({ is_viewed }) => !is_viewed).length,
    [alerts]
  );

  const addMockedAlert = useCallback(
    (mockedAlert) => {
      setMockedAlerts([...mockedAlerts, mockedAlert]);
    },
    [mockedAlerts, setMockedAlerts]
  );

  return (
    <AlertsContext.Provider
      value={{
        alerts,
        isAlertsLoading,
        reloadAlerts,
        unreadAlertsCount,
        mockedAlerts,
        addMockedAlert,
        setMockedAlerts
      }}
    >
      {children}
    </AlertsContext.Provider>
  );
}

export function useAlerts() {
  const context = useContext(AlertsContext);

  if (!context) {
    throw new Error('useAlerts must be used within a AlertsContextProvider');
  }

  return context;
}
