import React, { useContext } from 'react';

import { usePersonalLoanCategories } from 'services/settings';

const SettingsContext = React.createContext(null);

export function SettingsContextProvider({ children }) {
  const {
    data: personalLoanCategories = [],
    refetch: reloadPersonalLoanCategories,
    isLoading: isLoadingPersonalLoanCategories
  } = usePersonalLoanCategories();

  return (
    <SettingsContext.Provider
      value={{
        personalLoanCategories,
        isLoadingPersonalLoanCategories,
        reloadPersonalLoanCategories
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  const context = useContext(SettingsContext);

  if (!context) {
    throw new Error('useSettings must be used within a SettingsContextProvider');
  }

  return context;
}
