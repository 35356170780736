import { useState, useCallback, useEffect } from 'react';

import { useWebSocket } from 'hooks/useWebSocket';
import { useAlerts } from 'hooks/useAlerts';

import AlertToastCard, { FloatingAlertWrapper } from './AlertToastCard';

function Index() {
  const [newAlerts, setNewAlerts] = useState([]);
  const { registerTopic, unRegisterTopic } = useWebSocket();
  const { mockedAlerts, setMockedAlerts } = useAlerts();

  const onAlertReceived = (data) => {
    setNewAlerts([...newAlerts, data]);
  };

  const onClose = useCallback(
    (id) => {
      const alertIndex = newAlerts.findIndex(({ alert_id }) => alert_id === id);

      if (alertIndex !== -1) {
        setNewAlerts([
          ...newAlerts.slice(0, alertIndex),
          ...newAlerts.slice(alertIndex + 1, newAlerts.length)
        ]);
      }
    },
    [newAlerts, setNewAlerts]
  );

  useEffect(() => {
    registerTopic('NEW_ALERT', 'ALERTS_TOAST', onAlertReceived);

    return () => unRegisterTopic('NEW_ALERT', 'ALERTS_TOAST');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mockedAlerts?.length) {
      const alerts = mockedAlerts.filter(
        ({ alert_id }) => !newAlerts.find((alert) => alert.alert_id === alert_id)
      );

      if (alerts?.length) {
        setNewAlerts([...newAlerts, ...alerts]);
        setMockedAlerts([]);
      }
    }
  }, [newAlerts, setNewAlerts, mockedAlerts, setMockedAlerts]);

  return (
    <FloatingAlertWrapper>
      {newAlerts?.length
        ? newAlerts.map(({ type, metadata, alert_id }) => (
            <AlertToastCard
              key={alert_id}
              type={type}
              metadata={metadata}
              alertId={alert_id}
              onClose={onClose}
            />
          ))
        : null}
    </FloatingAlertWrapper>
  );
}

export default Index;
