import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const API = `${process.env.REACT_APP_API_BASE_URL}/offers`;

export const useOffersCategories = () =>
  useQuery({
    queryKey: ['offersCategories'],
    queryFn: async () => {
      const { data } = await axios.get(`${API}/categories`);

      return data;
    }
  });

export const getOffers = async (categoryId, loanPurpose) => {
  const resp = await axios.get(`${API}/categories/${categoryId}/?loan_purpose=${loanPurpose}`);

  return resp.data;
};
