import {
  PERSONAL_LOANS,
  PERSONAL_VEHICLE_FINANCE,
  PERSONAL_CREDIT_CARD,
  PERSONAL_FINANCIAL_PROFILE
} from './preferences';

export const MONEVO_CATEGORIES = [
  PERSONAL_LOANS,
  PERSONAL_VEHICLE_FINANCE,
  PERSONAL_CREDIT_CARD,
  PERSONAL_FINANCIAL_PROFILE
];
