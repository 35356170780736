import axios from 'axios';

const API = process.env.REACT_APP_API_BASE_URL;

export const syncGuest = async (data) => {
  let utm;

  try {
    utm = JSON.parse(sessionStorage.getItem('UTM_PARAMS'));
  } catch (e) {}

  const resp = await axios.post(`${API}/guest/sync`, {
    ...data,
    utm
  });

  return resp.data;
};
