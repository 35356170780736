import { useEffect } from 'react';
import { parse } from 'search-params';
import { useLocation } from 'react-router-dom';

const useUTMQueryStrings = () => {
  const { search } = useLocation();

  useEffect(() => {
    const paramsObj = parse(search);

    if (paramsObj) {
      const paramsKeys = Object.keys(paramsObj);

      if (paramsKeys.length) {
        const utmParams = paramsKeys
          .filter((paramKey) => paramKey.indexOf('utm_') === 0)
          .reduce((reduced, key) => {
            reduced[key] = paramsObj[key];

            return reduced;
          }, {});

        if (utmParams && Object.keys(utmParams).length) {
          sessionStorage.setItem('UTM_PARAMS', JSON.stringify(utmParams));
        }
      }
    }
  }, [search]);
};

export default useUTMQueryStrings;
