import { Navigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

import { useUserSelector } from 'hooks/useUser';
import { useUserProfileSelector } from 'hooks/useUserProfile';
import { ConnectionsContextProvider } from 'hooks/useConnections';
import { OffersCategoriesContextProvider } from 'hooks/useOffersCategories';
import { ProfileKpisContextProvider } from 'hooks/useProfileKpis';
import { ProfileCompletenessContextProvider } from 'hooks/useProfileCompleteness';
import { BrandsContextProvider } from 'hooks/useBrands';
import { Helmet } from 'react-helmet';

export function ProtectedRoute({ children, requiredProfileCompleted }) {
  const { isLoggedIn, isLoading } = useUserSelector();
  const { isUserProfileCompleted } = useUserProfileSelector();

  if (!isLoggedIn && !isLoading) {
    return <Navigate to="/login" replace />;
  }
  if (isLoading) {
    return <Spinner animation="grow" variant="info" size="lg" />;
  }
  if (!isUserProfileCompleted && requiredProfileCompleted) {
    return <Navigate to="/profile/intro" replace />;
  }

  return (
    <ConnectionsContextProvider>
      <OffersCategoriesContextProvider>
        <ProfileKpisContextProvider>
          <ProfileCompletenessContextProvider>
            <Helmet>
              <title>Upscore</title>
              <description></description>
            </Helmet>

            <BrandsContextProvider>{children}</BrandsContextProvider>
          </ProfileCompletenessContextProvider>
        </ProfileKpisContextProvider>
      </OffersCategoriesContextProvider>
    </ConnectionsContextProvider>
  );
}
