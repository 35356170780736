import { memo } from 'react';
import styled from 'styled-components';

const StyledHeading = styled.h1`
  color: ${(props) => `var(--${props.color || 'blue-100'});`};
  font-family: 'Plus Jakarta Sans', serif;
  font-style: normal;
  font-size: 56px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -1.44px;
  padding: 0;
  margin: 0;

  @media (max-width: 874px) {
    font-size: 36px;
  }
`;

const StyledTitle = styled.div`
  padding: 0;
  margin: 0;
  color: ${(props) => `var(--${props.color || 'blue-100'});`};
  font-family: 'Plus Jakarta Sans', serif;
  font-style: normal;
  ${(props) => (props?.textAlign ? `text-align: ${props.textAlign};` : '')};

  font-weight: ${(props) => {
    switch (props.variant) {
      case 'title-1':
        return '500';
      case 'title-2':
        return '500';
      case 'title-3':
        return '500';
      case 'title-4':
        return '500';
      case 'title-5':
        return '500';
      default:
        return 'inherit';
    }
  }};

  font-size: ${(props) => {
    switch (props.variant) {
      case 'title-1':
        return '48px';
      case 'title-2':
        return '36px';
      case 'title-3':
        return '30px';
      case 'title-4':
        return '24px';
      case 'title-5':
        return '21px';
      default:
        return 'inherit';
    }
  }};
  line-height: ${(props) => {
    switch (props.variant) {
      case 'title-1':
        return '110%';
      case 'title-2':
        return '110%';
      case 'title-3':
        return '110%';
      case 'title-4':
        return '120%';
      case 'title-5':
        return '120%';
      default:
        return 'inherit';
    }
  }};
  letter-spacing: ${(props) => {
    switch (props.variant) {
      case 'title-1':
        return '-1.92px';
      case 'title-2':
        return '-1.44px';
      case 'title-3':
        return '-1.2px';
      case 'title-4':
        return '-0.48px';
      case 'title-5':
        return '0';
      default:
        return 'inherit';
    }
  }};

  @media (max-width: 874px) {
    font-size: ${(props) => {
      switch (props.variant) {
        case 'title-1':
          return '36px';
        case 'title-2':
          return '32px';
        case 'title-3':
          return '27px';
        case 'title-4':
          return '24px';
        case 'title-5':
          return '21px';
        default:
          return 'inherit';
      }
    }};
  }
`;

const StyledLabel = styled.span`
  padding: 0;
  margin: 0;
  color: ${(props) => `var(--${props.color || 'blue-100'});`};
  font-family: 'Plus Jakarta Sans', serif;
  font-style: normal;
  line-height: 140%;
  letter-spacing: 0;
  ${(props) => (props?.textAlign ? `text-align: ${props.textAlign};` : '')};

  ${(props) =>
    props.elipsis
      ? `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
      : ''};

  font-size: ${(props) => {
    switch (props.variant) {
      case 'label-l':
        return '18px';
      case 'label-m':
        return '16px';
      case 'label-s':
        return '14px';
      case 'label-xs':
        return '10px';
      default:
        return 'inherit';
    }
  }};

  @media (max-width: 874px) {
    font-size: ${(props) => {
      switch (props.variant) {
        case 'label-l':
          return '16px';
        case 'label-m':
          return '14px';
        case 'label-s':
          return '12px';
        case 'label-xs':
          return '10px';
        default:
          return 'inherit';
      }
    }};
  }
`;

const StyledParagraph = styled.p`
  padding: 0;
  margin: 0;
  color: ${(props) => `var(--${props.color || 'blue-100'});`};
  font-family: 'Plus Jakarta Sans', serif;
  font-style: Regular;
  line-height: 140%;
  letter-spacing: -1%;
  white-space: ${(props) => (props.elipsis ? 'nowrap' : 'inherit')};
  overflow: ${(props) => (props.elipsis ? 'hidden' : 'inherit')};
  text-overflow: ${(props) => (props.elipsis ? 'ellipsis' : 'inherit')};

  font-size: ${(props) => {
    switch (props.variant) {
      case 'p-l':
        return '18px';
      case 'p-m':
        return '16px';
      default:
        return 'inherit';
    }
  }};

  @media (max-width: 874px) {
    font-size: ${(props) => {
      switch (props.variant) {
        case 'p-l':
          return '16px';
        case 'p-m':
          return '14px';
        default:
          return 'inherit';
      }
    }};
  }
`;

const StyledDigit = styled.span`
  padding: 0;
  margin: 0;
  color: ${(props) => `var(--${props.color || 'blue-100'});`};
  font-family: 'Roboto Mono', serif;
  font-style: Regular;
  line-height: 140%;
  letter-spacing: 0;
  font-size: ${(props) => {
    switch (props.variant) {
      case 'mono-xl':
        return '64px';
      case 'mono-l':
        return '32px';
      case 'mono-m':
        return '16px';
      case 'mono-s':
        return '10px';
      default:
        return 'inherit';
    }
  }};

  @media (max-width: 874px) {
    font-size: ${(props) => {
      switch (props.variant) {
        case 'mono-xl':
          return '36px';
        case 'mono-l':
          return '32px';
        case 'mono-m':
          return '16px';
        case 'mono-s':
          return '10px';
        default:
          return 'inherit';
      }
    }};
  }
`;

export const Heading = memo(({ color, children }) => {
  return <StyledHeading color={color}>{children}</StyledHeading>;
});

export const Title = memo(({ variant, color, children, textAlign }) => {
  return (
    <StyledTitle variant={variant} color={color} textAlign={textAlign}>
      {children}
    </StyledTitle>
  );
});

export const Paragraph = memo(({ variant, color, children, elipsis }) => {
  return (
    <StyledParagraph variant={variant} color={color} elipsis={elipsis}>
      {children}
    </StyledParagraph>
  );
});

export const Label = memo(({ variant, color, children, elipsis, textAlign }) => {
  return (
    <StyledLabel variant={variant} color={color} elipsis={elipsis} textAlign={textAlign}>
      {children}
    </StyledLabel>
  );
});

export const Digit = memo(({ variant, color, children }) => {
  return (
    <StyledDigit variant={variant} color={color}>
      {children}
    </StyledDigit>
  );
});
