import React, { useContext, useMemo, useState } from 'react';

import { useUserSelector } from 'hooks/useUser';
import { useCreditDetails as useCreditDetailsService } from '../services/equifax';

const CreditDetailsContext = React.createContext(null);

const attributeMapping = {
  BSC437: {
    description:
      'Your month-end credit card balance (across all your credit cards) over the last 6 months is 70% or less of your total credit limit.',
    insight: {
      title: 'Keep your credit use low',
      description:
        'Credit card balances that are managed comfortably below their credit limit indicate low credit risk and good financial management'
    },
    groups: {
      R: {
        description:
          'You do not currently have any  credit cards, or if you do have credit cards you are regularly over your credit limit.',
        values: ['R', 'C']
      },
      A: {
        description:
          'Your month-end credit card balance (across all your credit cards) over the last 6 months is between 71% and 100% of your total credit card limit.',
        values: [
          'A',
          '71',
          '72',
          '73',
          '74',
          '75',
          '76',
          '77',
          '78',
          '79',
          '80',
          '81',
          '82',
          '83',
          '84',
          '85',
          '86',
          '87',
          '88',
          '89',
          '90',
          '91',
          '92',
          '93',
          '94',
          '95',
          '96',
          '97',
          '98',
          '99',
          '100'
        ]
      },
      G: {
        description:
          'Your month-end credit card balance (across all your credit cards) over the last 6 months is 70% or less of your total credit limit.',
        values: [
          'G',
          '0',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30',
          '31',
          '32',
          '33',
          '34',
          '35',
          '36',
          '37',
          '38',
          '39',
          '40',
          '41',
          '42',
          '43',
          '44',
          '45',
          '46',
          '47',
          '48',
          '49',
          '50',
          '51',
          '52',
          '53',
          '54',
          '55',
          '56',
          '57',
          '58',
          '59',
          '60',
          '61',
          '62',
          '63',
          '64',
          '65',
          '66',
          '67',
          '68',
          '69',
          '70'
        ]
      },
      N: {
        description: 'You do not have any open credit accounts registered on your credit file.',
        values: ['N']
      }
    }
  },
  BSC023: {
    description: 'You have not withdrawn any cash on your credit cards in the last 3 months',
    insight: {
      title: 'No credit card cash withdrawals',
      description:
        'Muliple cash withdrawals from credit cards, over a short period of time, can indicate financial difficulties'
    },
    groups: {
      R: {
        description:
          'You either do not have a credit card, or if you do you have you have withdrawn cash 3 or more times from your credit cards in the last 3 months.',
        values: ['R', 'C']
      },
      A: {
        description:
          'You have withdrawn cash from your credit cards once or twice in the last 3 months.',
        values: ['A', '1', '2']
      },
      G: {
        description: 'You have not withdrawn any cash on your credit cards in the last 3 months',
        values: ['G', '0']
      },
      N: {
        description: 'You do not have any open credit accounts registered on your credit file.',
        values: ['N', 'M']
      }
    }
  },
  FSC104: {
    description: 'You have 2 or fewer unsecured loans',
    insight: {
      title: 'Keep unsecured loans low',
      description:
        'One or two loans on your credit file indicates creditworthiness without becoming over-indebted '
    },
    groups: {
      R: {
        description:
          'You do not currently have any unsecured loans, or if you do you have more than two unsecured loans',
        values: ['R', 'C']
      },
      G: {
        description: 'You have 2 or fewer unsecured loans',
        values: ['G', '1', '2']
      },
      N: {
        description: 'You do not have any open credit accounts registered on your credit file.',
        values: ['N', 'M']
      }
    }
  },
  FSC354: {
    description: 'You have one or more mortgages or unsecured loans',
    insight: {
      title: 'Unsecured loans',
      description:
        'Lenders like to see mortgage accounts on a credit file as it indicates a good credit rating, ability to pay and residential stability'
    },
    groups: {
      R: {
        description: 'You do not have any mortgage or secured loans',
        values: ['R', 'C']
      },
      G: {
        description: 'You have one or more mortgages or unsecured loans',
        values: ['G', '1']
      },
      N: {
        description: 'You do not have any open credit accounts registered on your credit file.',
        values: ['N', 'M']
      }
    }
  },
  SSC3: {
    description: 'You have 1 or fewer credit searches on your file in the last 6 months',
    insight: {
      title: 'No recent hard searches',
      description:
        'A modest number of hard credit searches on your file is fine, however a higher number can indicate that you may become over-indebted or that you are being turned down by other lenders.'
    },
    groups: {
      R: {
        description: 'You have 3 or more hard credit searches in the last 6 monthss',
        values: [
          'R',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30',
          '31',
          '32',
          '33',
          '34',
          '35',
          '36',
          '37',
          '38',
          '39',
          '40',
          '41',
          '42',
          '43',
          '44',
          '45',
          '46',
          '47',
          '48',
          '49',
          '50',
          '51',
          '52',
          '53',
          '54',
          '55',
          '56',
          '57',
          '58',
          '59',
          '60',
          '61',
          '62',
          '63',
          '64',
          '65',
          '66',
          '67',
          '68',
          '69',
          '70',
          '71',
          '72',
          '73',
          '74',
          '75',
          '76',
          '77',
          '78',
          '79',
          '80',
          '81',
          '82',
          '83',
          '84',
          '85',
          '86',
          '87',
          '88',
          '89',
          '90',
          '91',
          '92',
          '93',
          '94',
          '95',
          '96',
          '97',
          '98',
          '99'
        ]
      },
      A: {
        description: 'You have 2 hard credit searches on your fie in the last 6 months',
        values: ['A', '2']
      },
      G: {
        description: 'You have 1 or fewer credit searches on your file in the last 6 months',
        values: ['M', 'C', '0', '1']
      }
    }
  },
  PSC29: {
    description: 'You have no debt collection searches in the last 24 months',
    insight: {
      title: 'No debt collector searches'
    },
    groups: {
      R: {
        description: 'You have 2 or more debt collection search in the last 24 months',
        values: ['R']
      },
      A: {
        description: 'You have 1 debt collection search in the last 24 months',
        values: ['A']
      },
      G: {
        description: 'You have no debt collection searches in the last 24 months',
        values: ['G']
      }
    }
  },
  LSC325: {
    description:
      'You have either never been 4 or more months in arrears on a credit account or if you have it was more than 4 years ago.',
    insight: {
      title: 'No accounts in arrears',
      description:
        'Four or more months in arrears on credit accounts or previous credit defaults will be viewed as very high risk by lenders'
    },
    groups: {
      R: {
        description:
          'You have been 4 or more months in arrears on a credit account in the last 2 years',
        values: ['R', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
      },
      A: {
        description:
          'You have been 4 or more months in arrears on a credit account in the last 4 years',
        values: ['A', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48']
      },
      G: {
        description:
          'You have either never been 4 or more months in arrears on a credit account or if you have it was more than 4 years ago.',
        values: ['G']
      },
      N: {
        description: 'You do not have any open credit accounts registered on your credit file.',
        values: ['N', 'M']
      }
    }
  },
  CSC4: {
    description: 'You have no court data present from the last 6 years at their current address',
    insight: {
      title: 'No recent legal action'
    },
    groups: {
      R: {
        description: 'You have court data in the last 2 years at your current address',
        values: ['R', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
      },
      A: {
        description: 'You have court data in the last 6 years at your current address',
        values: ['A', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48']
      },
      G: {
        description:
          'You have no court data present from the last 6 years at their current address',
        values: ['G', 'C', 'M']
      }
    }
  },
  ESC14: {
    description:
      'You have been registered on the electoral roll for 3 or more years at your current address',
    insight: {
      title: 'Stay on the electoral roll',
      description:
        'Being on the Electoral Roll , and for a substantial period of time is viewed positively by lenders as it shows residential stability'
    },
    groups: {
      R: {
        description: 'You are not registered on the electoral roll at your current address  ',
        values: ['R', 'M']
      },
      A: {
        description:
          'You have been registered on the electoral roll for 2 or fewer years at your current address',
        values: ['C', '0', '1', '2']
      },
      G: {
        description:
          'You have been registered on the electoral roll for 3 or more years at your current address',
        values: [
          'G',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20'
        ]
      }
    }
  },
  SSC12: {
    description: 'You have no debt collection searches in the last 24 months',
    insight: {
      title: 'Debt collection searches',
      description:
        'Debt collections searches are viewed as indicators of high risk by lenders as it show that you have had significant unpaid credit arrears in the past.'
    },
    groups: {
      R: {
        description: 'You have 2 or more debt collection searches in the last 24 months',
        values: [
          'R',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '12',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30',
          '31',
          '32',
          '33',
          '34',
          '35',
          '36',
          '37',
          '38',
          '39',
          '40',
          '41',
          '42',
          '43',
          '44',
          '45',
          '46',
          '47',
          '48',
          '49',
          '50',
          '51',
          '52',
          '53',
          '54',
          '55',
          '56',
          '57',
          '58',
          '59',
          '60',
          '61',
          '62',
          '63',
          '64',
          '65',
          '66',
          '67',
          '68',
          '69',
          '70',
          '71',
          '72',
          '73',
          '74',
          '75',
          '76',
          '77',
          '78',
          '79',
          '80',
          '81',
          '82',
          '83',
          '84',
          '85',
          '86',
          '87',
          '88',
          '89',
          '90',
          '91',
          '92',
          '93',
          '94',
          '95',
          '96',
          '97',
          '98',
          '99'
        ]
      },
      A: {
        description: 'You have 1 debt collection search in the last 24 months',
        values: ['A', '1']
      },
      G: {
        description: 'You have no debt collection searches in the last 24 months',
        values: ['G', 'C', 'M']
      }
    }
  },
  LSC328: {
    description:
      'You have either never missed 1 or more payments, or the 1 or more missed payments occurred more than 1 year ago',
    insight: {
      title: 'Missed payments',
      description:
        'Missing payments on existing credit accounts will concern future lenders as to whether you can afford to take on more debt'
    },
    groups: {
      R: {
        description: 'You have missed 1 or more payments in the last 6 months',
        values: [
          'R',
          '1',
          '2',
          '3',
          '4',
          '5',
          '6',
          '7',
          '8',
          '9',
          '10',
          '11',
          '13',
          '14',
          '15',
          '16',
          '17',
          '18',
          '19',
          '20',
          '21',
          '22',
          '23',
          '24',
          '25',
          '26',
          '27',
          '28',
          '29',
          '30',
          '31',
          '32',
          '33',
          '34',
          '35',
          '36',
          '37',
          '38',
          '39',
          '40',
          '41',
          '42',
          '43',
          '44',
          '45',
          '46',
          '47',
          '48',
          '49',
          '50',
          '51',
          '52',
          '53',
          '54',
          '55',
          '56',
          '57',
          '58',
          '59',
          '60',
          '61',
          '62',
          '63',
          '64',
          '65',
          '66',
          '67',
          '68',
          '69',
          '70',
          '71',
          '72',
          '73',
          '74',
          '75',
          '76',
          '77',
          '78',
          '79',
          '80',
          '81',
          '82',
          '83',
          '84',
          '85',
          '86',
          '87',
          '88',
          '89',
          '90',
          '91',
          '92',
          '93',
          '94',
          '95',
          '96',
          '97',
          '98',
          '99'
        ]
      },
      A: {
        description: 'You have missed 1 or more payments in the last year',
        values: ['A', '12']
      },
      G: {
        description:
          'You have either never missed 1 or more payments, or the 1 or more missed payments occurred more than 1 year ago',
        values: ['G', 'C']
      },
      N: {
        description: 'You do not have any open credit accounts registered on your credit file.',
        values: ['N', 'M']
      }
    }
  },
  CD001: {
    description: 'You do not have any court data on your credit file',
    insight: {
      title: 'Court data',
      description:
        "CCJ's, Bankruptcies and other previous court actions are viewed as very high risk by lenders."
    },
    groups: {
      R: {
        description: 'You have court data on your credit file',
        values: ['R', '1']
      },
      G: {
        description: 'You do not have any court data on your credit file',
        values: ['G', 'M']
      }
    }
  }
};

const groupPriority = {
  R: 1,
  A: 2,
  N: 3,
  G: 4
};

export function CreditDetailsContextProvider({ children }) {
  const { user } = useUserSelector();
  const [month, setMonth] = useState('latest');
  const {
    data = [],
    isLoading: isCreditDetailsLoading,
    refetch: reloadCreditDetails
  } = useCreditDetailsService({
    enabled: !!user?.user_id && !!user?.profile_setup_completed,
    month
  });

  const creditDetails = useMemo(() => {
    return data
      .map(({ id, value }) => {
        const attributeDefinition = attributeMapping[id];

        const groupStatus =
          Object.keys(attributeDefinition.groups).find((groupStatus) => {
            const groupStatusDefinition = attributeDefinition.groups[groupStatus];

            return groupStatusDefinition.values.indexOf(value) !== -1;
          }) || 'N';

        return {
          id,
          key: id,
          label: attributeDefinition.description,
          insight: attributeDefinition.insight,
          status: groupStatus,
          description: attributeDefinition.groups?.[groupStatus]?.description
        };
      })
      .sort((a, b) => groupPriority[a.status] - groupPriority[b.status]);
  }, [data]);

  const warningItems = useMemo(
    () => creditDetails.filter(({ status }) => status !== 'G'),
    [creditDetails]
  );

  const successItems = useMemo(
    () => creditDetails.filter(({ status }) => status === 'G'),
    [creditDetails]
  );

  return (
    <CreditDetailsContext.Provider
      value={{
        month,
        setMonth,
        creditDetails,
        reloadCreditDetails,
        isCreditDetailsLoading,
        warningItems,
        successItems
      }}
    >
      {children}
    </CreditDetailsContext.Provider>
  );
}

export function useCreditDetails() {
  const context = useContext(CreditDetailsContext);

  if (!context) {
    throw new Error('useCreditDetails must be used within a CreditDetailsContext');
  }

  return context;
}
