import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const API = `${process.env.REACT_APP_API_BASE_URL}/profile`;

export const savePersonalData = async (data) => {
  const resp = await axios.post(`${API}/personal_data`, data);

  return resp.data;
};

export const getSelfiesS3PresignedUrl = async (fileExtension) => {
  const resp = await axios.get(`${API}/selfie/presigned-url/${fileExtension}`);

  return resp.data;
};

export const usePersonalData = ({ enabled }) =>
  useQuery({
    queryKey: ['personalData'],
    queryFn: async () => {
      const { data } = await axios.get(`${API}/personal_data`);

      return data;
    },
    enabled
  });
export const useProfileCompleteness = ({ enabled }) =>
  useQuery({
    queryKey: ['completeness'],
    queryFn: async () => {
      const { data } = await axios.get(`${API}/completeness`);

      return data;
    },
    enabled
  });

export const useImproveProfileTasks = ({ enabled }) =>
  useQuery({
    queryKey: ['improveProfileTasks'],
    queryFn: async () => {
      const { data } = await axios.get(`${API}/tasks`);

      return data;
    },
    enabled
  });

export const useProfileKpis = () =>
  useQuery({
    queryKey: ['profileKpis'],
    queryFn: async () => {
      const { data } = await axios.get(`${API}/kpis`);

      return data;
    }
  });

export const completeProfileSetup = async () => await axios.post(`${API}/complete`, {});

export const completePendingTask = async (action) =>
  await axios.post(`${API}/tasks/${action}/complete`, {});

export const updatePendingTask = async (action, data) =>
  await axios.patch(`${API}/tasks/${action}`, data);

export const savePreferences = async (data) => await axios.post(`${API}/preferences`, data);

export const contactUs = async (data) => await axios.post(`${API}/contact`, data);
