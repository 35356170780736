import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const API = `${process.env.REACT_APP_API_BASE_URL}/alerts`;

export const markAlertAsViewed = async (alertId) => {
  const resp = await axios.post(`${API}/${alertId}/view`, {});

  return resp.data;
};

export const createAlert = async (data) => {
  const resp = await axios.post(`${API}/`, data);

  return resp.data;
};

export const useAlerts = ({ enabled }) =>
  useQuery({
    queryKey: ['alerts'],
    queryFn: async () => {
      const { data } = await axios.get(`${API}`);

      return data;
    },
    enabled
  });
