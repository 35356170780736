import React, { useContext } from 'react';

import { useGTM } from 'hooks/useGTM';
import { useFacebookPixel } from 'hooks/useFacebookPixel';
import { useHotjar } from 'hooks/useHotjar';
import { useCustomerIo } from 'hooks/useCustomerIo';

const TrackingContext = React.createContext(null);

export function TrackingContextProvider({ children }) {
  const GTM = useGTM();
  const FBP = useFacebookPixel();
  const HJ = useHotjar();
  const CIO = useCustomerIo();

  const identify = ({ gtmEnabled = true, fbpEnabled = true, hjEnabled = true, data }) => {
    if (gtmEnabled) {
      GTM.identify(data);
    }

    if (fbpEnabled) {
      FBP.identify(data);
    }

    if (hjEnabled) {
      HJ.identify(data);
    }
  };

  const trackPage = ({ gtmEnabled = true, fbpEnabled = true, hjEnabled = true, data }) => {
    if (gtmEnabled) {
      GTM.trackPage(data);
    }

    if (fbpEnabled) {
      FBP.trackPage(data);
    }

    if (hjEnabled) {
      HJ.trackPage(data);
    }
  };

  const trackCustomEvent = ({
    gtmEnabled = true,
    fbpEnabled = true,
    hjEnabled = true,
    cioEnabled = true,
    topic,
    payload
  }) => {
    if (gtmEnabled) {
      GTM.trackCustomEvent(topic, payload);
    }

    if (fbpEnabled) {
      FBP.trackCustomEvent(topic, payload);
    }

    if (hjEnabled) {
      HJ.trackCustomEvent(topic, payload);
    }

    if (cioEnabled) {
      CIO.trackCustomEvent(topic, payload);
    }
  };

  return (
    <TrackingContext.Provider
      value={{
        identify,
        trackPage,
        trackCustomEvent
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
}

export function useTracking() {
  const context = useContext(TrackingContext);

  if (!context) {
    throw new Error('useTracking must be used within a TrackingContext');
  }

  return context;
}
