import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const API = `${process.env.REACT_APP_API_BASE_URL}/settings`;

export const usePersonalLoanCategories = () =>
  useQuery({
    queryKey: ['personalLoanCategories'],
    queryFn: async () => {
      const { data } = await axios.get(`${API}/loan-categories/personal`);

      return data;
    }
  });
