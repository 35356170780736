import 'react-responsive-carousel/lib/styles/carousel.min.css';
import styled from 'styled-components';
import Toast from 'react-bootstrap/Toast';

import AlertCard from '../AlertCard';

const ToastWrapper = styled(Toast)`
  position: relative;
  margin-top: 10px;
`;

const ToastHeader = styled(Toast.Header)`
  border-bottom: 0;
  justify-content: end;
  padding: 0;
  position: absolute;
  top: 5px;
  right: 10px;
  background: transparent;
`;

const ToastBody = styled(Toast.Body)`
  padding: 0;
`;

export const FloatingAlertWrapper = styled.div`
  position: fixed;
  top: 14px;
  right: 14px;
  bottom: inherit;
  z-index: 999;
  width: 375px;

  @media (max-width: 992px) {
    top: inherit;
    bottom: 90px;
  }
`;

function AlertToastCard({ type, metadata, alertId, onClose, delay = 10000, autohide = true }) {
  return (
    <ToastWrapper onClose={() => onClose(alertId)} show delay={delay} autohide={autohide}>
      <ToastHeader />
      <ToastBody>
        <AlertCard type={type} metadata={metadata} isViewed alertId={alertId} />
      </ToastBody>
    </ToastWrapper>
  );
}

export default AlertToastCard;
