import React, { useContext, useCallback } from 'react';
import { hotjar } from 'react-hotjar';

const HotjarContext = React.createContext(null);
const { REACT_APP_ENV, REACT_APP_HOTJAR_ID, REACT_APP_HOTJAR_SV } = process.env;

if (REACT_APP_ENV === 'prod') {
  hotjar.initialize(REACT_APP_HOTJAR_ID, REACT_APP_HOTJAR_SV);
}

export function HotjarContextProvider({ children }) {
  const devIdentify = useCallback((...data) => console.log('HJ.identify', ...data), []);
  const devTrackPage = useCallback((...data) => console.log('HJ.trackPage', ...data), []);
  const devCustomEvent = useCallback((...data) => console.log('HJ.customEvent', ...data), []);

  if (REACT_APP_ENV !== 'prod') {
    return (
      <HotjarContext.Provider
        value={{
          identify: devIdentify,
          trackPage: devTrackPage,
          trackCustomEvent: devCustomEvent
        }}
      >
        {children}
      </HotjarContext.Provider>
    );
  }

  return (
    <HotjarContext.Provider
      value={{
        identify: hotjar.identify,
        trackPage: hotjar.stateChange,
        trackCustomEvent: hotjar.event
      }}
    >
      {children}
    </HotjarContext.Provider>
  );
}

export function useHotjar() {
  const context = useContext(HotjarContext);

  if (!context) {
    throw new Error('useHotjar must be used within a HotjarContext');
  }

  return context;
}
