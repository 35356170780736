import React, { useContext, useEffect, useMemo } from 'react';

import { useProfileKpis as useProfileKpisService } from 'services/profile';

import { useWebSocket } from 'hooks/useWebSocket';

const ProfileKpisContext = React.createContext(null);

export function ProfileKpisContextProvider({ children }) {
  const { data = [], isLoading, refetch } = useProfileKpisService();
  const { onDataRefreshMessage } = useWebSocket();

  useEffect(() => {
    onDataRefreshMessage('PROFILE_KPIS', refetch);
  }, [onDataRefreshMessage, refetch]);

  const items = useMemo(() => data.filter(({ type }) => type !== 'CREDIT_RISK'), [data]);

  return (
    <ProfileKpisContext.Provider
      value={{
        data: items,
        isLoading,
        reloadProfileKpis: refetch
      }}
    >
      {children}
    </ProfileKpisContext.Provider>
  );
}

export function useProfileKpis() {
  const context = useContext(ProfileKpisContext);

  if (!context) {
    throw new Error('useProfileKpis must be used within a ProfileKpisContextProvider');
  }

  return context;
}
