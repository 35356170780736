import { useContext, createContext, useState, useMemo, useCallback } from 'react';

import { useBrands as useBrandsQuery } from 'services/brands';

const BrandsContext = createContext(null);

const BRANDS_PER_PAGE = 25;

export const applyFilters = (data, categories) => {
  if (!categories?.length) {
    return data;
  }

  const categoryIds = categories.map(({ id }) => id);

  return data.filter((item) => {
    return item.categories?.some((category) => categoryIds.includes(category));
  });
};

export const BrandsContextProvider = ({ children }) => {
  const { data, isLoading: isBrandsLoading } = useBrandsQuery();
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(1);

  const brands = useMemo(() => {
    return applyFilters(data, categories)?.slice(0, page * BRANDS_PER_PAGE) || [];
  }, [page, data, categories]);

  const hasNextPage = useMemo(() => {
    return applyFilters(data, categories)?.length > page * BRANDS_PER_PAGE;
  }, [data, page, categories]);

  const fetchNextPage = useCallback(() => {
    setPage((page) => page + 1);
  }, [setPage]);

  return (
    <BrandsContext.Provider
      value={{
        data,
        brands,
        isBrandsLoading,
        hasNextPage,
        fetchNextPage,
        categories,
        setCategories
      }}
    >
      {children}
    </BrandsContext.Provider>
  );
};

export function useBrands() {
  const context = useContext(BrandsContext);

  if (!context) {
    throw new Error('useBrands must be used within a BrandsContextProvider');
  }

  return context;
}
