import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const API = `${process.env.REACT_APP_API_BASE_URL}/connections`;

export const useConnections = () =>
  useQuery({
    queryKey: ['connections'],
    queryFn: async () => {
      const { data } = await axios.get(`${API}`);

      return data;
    }
  });

export const toggleConnection = async (type) => await axios.post(`${API}/${type}/toggle`, {});
