import React, { useContext, useEffect, useState, useMemo } from 'react';

import { useImproveProfileTasks as useImproveProfileTasksService } from 'services/profile';

import { useWebSocket } from 'hooks/useWebSocket';
import { useUserSelector } from 'hooks/useUser';
import { useFlags } from './useFlags';

const ImproveProfileTasksContext = React.createContext(null);

const pendingTasksOrder = {
  SPRIVE: 1,
  MONEVO: 2,
  PLAID: 3,
  CREDIT_BUREAU: 4,
  METRIXS_APP: 5,
  DIRECT_ID: 6,
  PREFERENCES_SETUP: 7
};

export function ImproveProfileTasksContextProvider({ children }) {
  const { user } = useUserSelector();
  const {
    data = [],
    isLoading,
    isFetching,
    refetch
  } = useImproveProfileTasksService({
    enabled: !!user?.user_id && !!user?.profile_setup_completed
  });
  const { onDataRefreshMessage } = useWebSocket();
  const { metrixsEnabled, directIdEnabled } = useFlags();
  const [improveProfileTasks, setImproveProfileTasks] = useState([]);

  useEffect(() => {
    onDataRefreshMessage('IMPROVE_PROFILE_TASKS', refetch);
  }, [onDataRefreshMessage, refetch]);

  useEffect(() => {
    if (data?.length) {
      setImproveProfileTasks(
        data
          .filter(({ action }) => {
            if (action === 'METRIXS_APP') {
              return metrixsEnabled || !!user?.products?.real_estate;
            }
            if (action === 'DIRECT_ID') {
              return directIdEnabled;
            }

            return true;
          })
          .sort((a, b) => (pendingTasksOrder[a.action] > pendingTasksOrder[b.action] ? 1 : -1))
      );
    }
  }, [data, user, metrixsEnabled, directIdEnabled]);

  const isBankAccountConnected = useMemo(() => {
    return !improveProfileTasks.find(({ action }) => action === 'PLAID');
  }, [improveProfileTasks]);

  const isFinancialInfoSubmitted = useMemo(() => {
    return !improveProfileTasks.find(({ action }) => action === 'CREDIT_BUREAU');
  }, [improveProfileTasks]);

  const isMonevoFormSubmitted = useMemo(() => {
    return !improveProfileTasks.find(({ action }) => action === 'MONEVO');
  }, [improveProfileTasks]);

  const hasBankAccountConnectionErrors = useMemo(() => {
    const task = improveProfileTasks.find(({ action }) => action === 'PLAID');

    return !!task?.error || task?.is_expired;
  }, [improveProfileTasks]);

  const hasFinancialInfoSubmitionErrors = useMemo(() => {
    const task = improveProfileTasks.find(({ action }) => action === 'CREDIT_BUREAU');

    return !!task?.error;
  }, [improveProfileTasks]);

  const hasSelectedPreferences = useMemo(() => {
    return !!user?.preferences?.personal?.values?.length;
  }, [user]);

  return (
    <ImproveProfileTasksContext.Provider
      value={{
        isMonevoFormSubmitted,
        isBankAccountConnected,
        isFinancialInfoSubmitted,
        hasBankAccountConnectionErrors,
        hasFinancialInfoSubmitionErrors,
        data: improveProfileTasks,
        isLoading,
        isFetching,
        reloadProfileTasks: refetch,
        hasSelectedPreferences
      }}
    >
      {children}
    </ImproveProfileTasksContext.Provider>
  );
}

export function useImproveProfileTasks() {
  const context = useContext(ImproveProfileTasksContext);

  if (!context) {
    throw new Error(
      'useImproveProfileTasks must be used within a ImproveProfileTasksContextProvider'
    );
  }

  return context;
}
