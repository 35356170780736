import { memo } from 'react';
import { Spinner as RBSpinner } from 'react-bootstrap';
import styled from 'styled-components';

const SpinnerWrapper = styled.div`
  width: 100%;
  text-align: center;
`;

const StyledSpinner = styled(RBSpinner)`
  background-color: var(${(props) => (props.color === 'primary' ? '--blue-100' : '--white')});
`;

const Spinner = ({ color = 'primary' }) => {
  return (
    <SpinnerWrapper>
      <StyledSpinner animation="grow" variant="info" size="lg" color={color} />
    </SpinnerWrapper>
  );
};

export default memo(Spinner);
