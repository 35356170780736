import en from './English.json';
import enShort from './short/English.json';

import es from './Spanish.json';
import esShort from './short/Spanish.json';

import pt from './Portuguese.json';
import ptShort from './short/Portuguese.json';

import de from './German.json';
import deShort from './short/German.json';

import fr from './French.json';
import frShort from './short/French.json';

import no from './Norwegian.json';
import noShort from './short/Norwegian.json';

import se from './Swedish.json';
import seShort from './short/Swedish.json';

import it from './Italian.json';
import itShort from './short/Italian.json';

import fi from './Finnish.json';
import fiShort from './short/Finnish.json';

import nl from './Dutch.json';
import nlShort from './short/Dutch.json';

import ro from './Romanian.json';
import roShort from './short/Romanian.json';

import pl from './Portuguese.json';
import plShort from './short/Portuguese.json';

import dk from './Danish.json';
import dkShort from './short/Danish.json';

export const languages = {
  en: {
    ...en,
    ...enShort
  },
  es: {
    ...es,
    ...esShort
  },
  pt: {
    ...pt,
    ...ptShort
  },
  de: {
    ...de,
    ...deShort
  },
  fr: {
    ...fr,
    ...frShort
  },
  no: {
    ...no,
    ...noShort
  },
  se: {
    ...se,
    ...seShort
  },
  it: {
    ...it,
    ...itShort
  },
  fi: {
    ...fi,
    ...fiShort
  },
  nl: {
    ...nl,
    ...nlShort
  },
  ro: {
    ...ro,
    ...roShort
  },
  pl: {
    ...pl,
    ...plShort
  },
  dk: {
    ...dk,
    ...dkShort
  }
};
