import React, { useContext, useCallback } from 'react';
import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import googleAnalytics from '@analytics/google-analytics';

const GTMContext = React.createContext(null);
const {
  REACT_APP_ENV,
  REACT_APP_GTM_NAME,
  REACT_APP_GTM_CONTAINER_ID,
  REACT_APP_MEASUREMENT_ID_1
} = process.env;

let analytics;

if (REACT_APP_ENV === 'prod') {
  analytics = Analytics({
    app: REACT_APP_GTM_NAME,
    plugins: [
      googleTagManager({
        containerId: REACT_APP_GTM_CONTAINER_ID
      }),
      googleAnalytics({
        measurementIds: [REACT_APP_MEASUREMENT_ID_1]
      })
    ]
  });
}

export function GTMContextProvider({ children }) {
  const devIdentify = useCallback((...data) => console.log('GTM.identify', ...data), []);
  const devTrackPage = useCallback((...data) => console.log('GTM.trackPage', ...data), []);
  const devCustomEvent = useCallback((...data) => console.log('GTM.customEvent', ...data), []);

  if (REACT_APP_ENV !== 'prod') {
    return (
      <GTMContext.Provider
        value={{
          identify: devIdentify,
          trackPage: devTrackPage,
          trackCustomEvent: devCustomEvent
        }}
      >
        {children}
      </GTMContext.Provider>
    );
  }

  return (
    <GTMContext.Provider
      value={{
        identify: analytics.identify,
        trackPage: analytics.page,
        trackCustomEvent: analytics.track
      }}
    >
      {children}
    </GTMContext.Provider>
  );
}

export function useGTM() {
  const context = useContext(GTMContext);

  if (!context) {
    throw new Error('useGTM must be used within a GTMContext');
  }

  return context;
}
