import axios from 'axios';
import { useQuery } from '@tanstack/react-query';

const API = `${process.env.REACT_APP_API_BASE_URL}/equifax`;

export const startConnection = async () => {
  const resp = await axios.post(`${API}/start`, {});

  return resp.data;
};

export const useCreditDetails = ({ enabled, month }) =>
  useQuery({
    queryKey: ['creditDetails', month],
    queryFn: async () => {
      const { data } = await axios.get(`${API}/credit-details/details?month=${month}`);

      return data;
    },
    enabled
  });
