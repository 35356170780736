import React, { useContext, useCallback } from 'react';
import axios from 'axios';

const CustomerIoContext = React.createContext(null);
const { REACT_APP_ENV, REACT_APP_API_BASE_URL } = process.env;

const addToSegment = async (topic) => {
  const jwtTokens = localStorage.getItem('jwt-tokens');
  if (jwtTokens === 'undefined' || jwtTokens === null) return;
  const { accessToken, idToken } = JSON.parse(jwtTokens);
  if (accessToken) {
    axios.defaults.headers.common.Authorization = accessToken;
    axios.defaults.headers.common.IdToken = idToken;
    await axios({
      method: 'POST',
      url: `${REACT_APP_API_BASE_URL}/customerio/segment`,
      data: { topic }
    });
  }
};

export function CustomerIoContextProvider({ children }) {
  const devCustomEvent = useCallback(
    (...data) => console.log('CustomerIo.customEvent', ...data),
    []
  );

  if (REACT_APP_ENV !== 'prod') {
    return (
      <CustomerIoContext.Provider
        value={{
          trackCustomEvent: devCustomEvent
        }}
      >
        {children}
      </CustomerIoContext.Provider>
    );
  }

  return (
    <CustomerIoContext.Provider
      value={{
        trackCustomEvent: (topic) => addToSegment(topic)
      }}
    >
      {children}
    </CustomerIoContext.Provider>
  );
}

export function useCustomerIo() {
  const context = useContext(CustomerIoContext);

  if (!context) {
    throw new Error('useCustomerIo must be used within a CustomerIoContext');
  }

  return context;
}
