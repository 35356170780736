const Image = ({ name, path = '', src, alt, ...rest }) => {
  if (src) {
    return <img src={src} alt={alt} {...rest} />;
  }

  return (
    <picture>
      <source srcSet={`/images/compressed/${path}${name}.webp`} type="image/webp" {...rest} />
      <source srcSet={`/images/resized/${path}${name}.jpg`} type="image/jpeg" {...rest} />
      <img src={`/images/resized/${path}${name}.jpg`} alt={alt} {...rest} />
    </picture>
  );
};

export default Image;
