import React, { useContext, useEffect, useMemo } from 'react';

import {
  useReferralEarnings as useReferralEarningsService,
  useReferralEarningsHistory as useReferralEarningsHistoryService,
  useReferralUsers as useReferralUsersService
} from 'services/referral';

import { useWebSocket } from 'hooks/useWebSocket';
import { useUserSelector } from 'hooks/useUser';

const ReferralContext = React.createContext(null);

export function ReferralContextProvider({ children }) {
  const { user } = useUserSelector();
  const {
    data: referralEarnings = {},
    isLoading: isReferralEarningsLoading,
    refetch: reloadReferralEarnings
  } = useReferralEarningsService({
    enabled: !!user?.user_id && !!user?.profile_setup_completed
  });
  const {
    data: referralEarningsHistoryPages = [],
    isLoading: isReferralEarningsHistoryLoading,
    refetch: reloadReferralEarningsHistory,
    hasNextPage: hasNextPageReferralEarningsHistory,
    fetchNextPage: fetchNextPageReferralEarningsHistory
  } = useReferralEarningsHistoryService({
    enabled: !!user?.user_id && !!user?.profile_setup_completed
  });
  const {
    data: referralUsersPages = [],
    isLoading: isReferralUsersLoading,
    refetch: reloadReferralUsers,
    hasNextPage: hasNextPageReferralUsers,
    fetchNextPage: fetchNextPageReferralUsers
  } = useReferralUsersService({
    enabled: !!user?.user_id && !!user?.profile_setup_completed
  });
  const { onDataRefreshMessage } = useWebSocket();

  useEffect(() => {
    onDataRefreshMessage('REFERRAL_EARNINGS', reloadReferralEarnings);
    onDataRefreshMessage('REFERRAL_USERS', reloadReferralUsers);
    onDataRefreshMessage('REFERRAL_EARNINGS_HISTORY', reloadReferralEarningsHistory);
  }, [
    onDataRefreshMessage,
    reloadReferralEarningsHistory,
    reloadReferralUsers,
    reloadReferralEarnings
  ]);

  const referralEarningsHistory = useMemo(
    () => referralEarningsHistoryPages?.pages?.map(({ data }) => data)?.flat() || [],
    [referralEarningsHistoryPages]
  );
  const referralUsers = useMemo(
    () => referralUsersPages?.pages?.map(({ data }) => data)?.flat() || [],
    [referralUsersPages]
  );

  return (
    <ReferralContext.Provider
      value={{
        referralEarnings,
        isReferralEarningsLoading,
        reloadReferralEarnings,
        referralEarningsHistory,
        isReferralEarningsHistoryLoading,
        reloadReferralEarningsHistory,
        referralUsers,
        isReferralUsersLoading,
        reloadReferralUsers,
        hasNextPageReferralEarningsHistory,
        fetchNextPageReferralEarningsHistory,
        hasNextPageReferralUsers,
        fetchNextPageReferralUsers
      }}
    >
      {children}
    </ReferralContext.Provider>
  );
}

export function useReferral() {
  const context = useContext(ReferralContext);

  if (!context) {
    throw new Error('useReferral must be used within a ReferralContextProvider');
  }

  return context;
}
