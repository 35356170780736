import React, { lazy, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Loadable from './components/Loadable';
import { ProtectedRoute } from './components/ProtectedRoute';
import { useTracking } from './hooks/useTracking';
import { ReferralContextProvider } from './hooks/useReferral';
import { CreditDetailsContextProvider } from './hooks/useCreditDetails';
import { PassportContextProvider } from './hooks/usePassport';
import { useFlags } from './hooks/useFlags';

const Register = Loadable(lazy(() => import('./pages/account/Register')));
const Login = Loadable(lazy(() => import('./pages/account/Login')));
const PasswordRecover = Loadable(lazy(() => import('./pages/account/PasswordRecover')));
const PasswordRecoverConfirm = Loadable(
  lazy(() => import('./pages/account/PasswordRecoverConfirm'))
);
const RegisterConfirm = Loadable(lazy(() => import('./pages/account/RegisterConfirm')));
const MoreRegisterConfirmOptions = Loadable(
  lazy(() => import('./pages/account/MoreRegisterConfirmOptions'))
);
const RegisterChangeEmailConfirmOptions = Loadable(
  lazy(() => import('./pages/account/RegisterChangeEmailConfirmOptions'))
);
const RegisterChangePhoneConfirmOptions = Loadable(
  lazy(() => import('./pages/account/RegisterChangePhoneConfirmOptions'))
);

const Home = Loadable(lazy(() => import('./pages/home/Home')));
const OffersCategories = Loadable(lazy(() => import('./pages/offers/OffersCategories')));
const Offers = Loadable(lazy(() => import('./pages/offers/Offers')));
const OffersSetup = Loadable(lazy(() => import('./pages/offersSetup/OffersSetup')));
const Referrals = Loadable(lazy(() => import('./pages/referrals/Referrals')));
const ReferralsWithdrawal = Loadable(lazy(() => import('./pages/referrals/ReferralsWithdrawal')));
const Rewards = Loadable(lazy(() => import('./pages/rewards/Rewards')));
const RewardDetails = Loadable(lazy(() => import('./pages/rewards/RewardDetails')));
const BuyReward = Loadable(lazy(() => import('./pages/rewards/BuyReward')));
const RewardsPurchases = Loadable(lazy(() => import('./pages/rewards/RewardsPurchases')));
const FinancePassportTMLandingPageView = Loadable(
  lazy(() => import('./pages/passport/FinancePassportTMLandingPageView'))
);
const PassportOnboardingLandingPageView = Loadable(
  lazy(() => import('./pages/passport/PassportOnboardingLandingPageView'))
);
const PassportOnboardingDetails = Loadable(
  lazy(() => import('./pages/passport/PassportOnboardingDetails'))
);
const PassportOnboardingResume = Loadable(
  lazy(() => import('./pages/passport/PassportOnboardingResume'))
);
const PassportOnboardingThankYouPage = Loadable(
  lazy(() => import('./pages/passport/PassportOnboardingThankYouPage'))
);
const Passport = Loadable(lazy(() => import('./pages/passport/Passport')));
const PassportRequestView = Loadable(lazy(() => import('./pages/passport/PassportRequestView')));
const PassportDetails = Loadable(lazy(() => import('./pages/passport/PassportDetails')));
const PassportRequest = Loadable(lazy(() => import('./pages/passport/PassportRequest')));
const PassportDocuments = Loadable(lazy(() => import('./pages/passport/PassportDocuments')));
const Notifications = Loadable(lazy(() => import('./pages/notifications/Notifications')));
const Settings = Loadable(lazy(() => import('./pages/settings/Settings')));
const SettingsRemove = Loadable(lazy(() => import('./pages/settings/RemoveAccount')));
const SettingsPersonalInformation = Loadable(
  lazy(() => import('./pages/settings/PersonalInformation'))
);
const SettingsFinancialInformation = Loadable(
  lazy(() => import('./pages/settings/FinancialInformation'))
);
const SettingsBankDetails = Loadable(lazy(() => import('./pages/settings/BankDetails')));
const SettingsBankDetailsSubmit = Loadable(
  lazy(() => import('./pages/settings/BankDetailsSubmit'))
);
const SettingsConnections = Loadable(lazy(() => import('./pages/settings/Connections')));
const SettingsPreferences = Loadable(lazy(() => import('./pages/settings/Preferences')));
const SettingsHelp = Loadable(lazy(() => import('./pages/settings/Help')));
const SubmitFinancialInfo = Loadable(
  lazy(() => import('./pages/financialInfo/SubmitFinancialInfo'))
);
const MonevoConnect = Loadable(lazy(() => import('./pages/monevo/MonevoConnect')));
const AboundConnect = Loadable(lazy(() => import('./pages/abound/AboundConnect')));
const PaymentOutroPage = Loadable(lazy(() => import('./pages/payment/OutroPage')));
const CreditScoreDetails = Loadable(lazy(() => import('./pages/creditScore/CreditScoreDetails')));
const CreditScoreDetailsInsights = Loadable(
  lazy(() => import('./pages/creditScore/CreditScoreDetailsInsights'))
);
const ProfileSetupRegisterIntroFlow = Loadable(
  lazy(() => import('./pages/profile/RegisterFlows/OnboardingFlow'))
);

function RoutesComponent() {
  const location = useLocation();
  const { trackPage } = useTracking();
  const { isPassportOnlyEnabled } = useFlags();

  useEffect(() => {
    trackPage({
      data: location?.pathname
    });
  }, [location, trackPage]);

  if (isPassportOnlyEnabled) {
    return (
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/register/:email" element={<Register />} />
        <Route path="/register/confirm" element={<RegisterConfirm />} />
        <Route path="/register/confirm/:email" element={<RegisterConfirm />} />
        <Route path="/register/confirm/:email/:code" element={<RegisterConfirm />} />
        <Route
          path="/register/confirm/more-options/:email"
          element={<MoreRegisterConfirmOptions />}
        />
        <Route
          path="/register/confirm/more-options/:email/email"
          element={<RegisterChangeEmailConfirmOptions />}
        />
        <Route
          path="/register/confirm/more-options/:email/phone"
          element={<RegisterChangePhoneConfirmOptions />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/login/:email" element={<Login />} />
        <Route path="/password/recover" element={<PasswordRecover />} />
        <Route path="/password/recover/confirm/:email" element={<PasswordRecoverConfirm />} />
        <Route path="/password/recover/confirm/:email/:code" element={<PasswordRecoverConfirm />} />

        <Route path="/finance-passport-tm" element={<FinancePassportTMLandingPageView />} />
        <Route
          path="/finance-passport-tm/:referralCode"
          element={<FinancePassportTMLandingPageView />}
        />
        <Route
          path="/onboarding/passport/:countryCode"
          element={<PassportOnboardingLandingPageView />}
        />
        <Route
          path="/onboarding/passport/:countryCode/stage-2"
          element={<PassportOnboardingDetails />}
        />
        <Route path="/onboarding/passport/resume" element={<PassportOnboardingResume />} />
        <Route
          path="/onboarding/passport/:countryCode/thank-you"
          element={<PassportOnboardingThankYouPage />}
        />

        <Route
          path="/"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <Home />
            </ProtectedRoute>
          }
        />

        <Route
          path="/passport"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <PassportContextProvider>
                <Passport />
              </PassportContextProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/passport/:country_code"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <PassportContextProvider>
                <PassportRequestView />
              </PassportContextProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/passport/details"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <PassportDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/passport/request"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <PassportContextProvider>
                <PassportRequest />
              </PassportContextProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/passport/request/:country_code"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <PassportContextProvider>
                <PassportRequest />
              </PassportContextProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/passport/request/update"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <PassportContextProvider>
                <PassportRequest updatePassportMode />
              </PassportContextProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/passport/documents/:bankName"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <PassportContextProvider>
                <PassportDocuments />
              </PassportContextProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <Notifications />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/remove"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <SettingsRemove />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/personal-information"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <SettingsPersonalInformation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/financial-information"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <SettingsFinancialInformation />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/bank-details"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <SettingsBankDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/bank-details/submit"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <SettingsBankDetailsSubmit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/connections"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <SettingsConnections />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/preferences"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <SettingsPreferences />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings/help"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <SettingsHelp />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/intro"
          element={
            <ProtectedRoute>
              <ProfileSetupRegisterIntroFlow />
            </ProtectedRoute>
          }
        />
        <Route
          path="/referrals"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <ReferralContextProvider>
                <Referrals />
              </ReferralContextProvider>
            </ProtectedRoute>
          }
        />
        <Route
          path="/referrals/withdrawal"
          element={
            <ProtectedRoute requiredProfileCompleted>
              <ReferralContextProvider>
                <ReferralsWithdrawal />
              </ReferralContextProvider>
            </ProtectedRoute>
          }
        />
        <Route path="/r/:refId" element={<Register />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/register" element={<Register />} />
      <Route path="/register/:email" element={<Register />} />
      <Route path="/register/confirm" element={<RegisterConfirm />} />
      <Route path="/register/confirm/:email" element={<RegisterConfirm />} />
      <Route path="/register/confirm/:email/:code" element={<RegisterConfirm />} />
      <Route
        path="/register/confirm/more-options/:email"
        element={<MoreRegisterConfirmOptions />}
      />
      <Route
        path="/register/confirm/more-options/:email/email"
        element={<RegisterChangeEmailConfirmOptions />}
      />
      <Route
        path="/register/confirm/more-options/:email/phone"
        element={<RegisterChangePhoneConfirmOptions />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/login/:email" element={<Login />} />
      <Route path="/login/:email/:password" element={<Login />} />
      <Route path="/password/recover" element={<PasswordRecover />} />
      <Route path="/password/recover/confirm/:email" element={<PasswordRecoverConfirm />} />
      <Route path="/password/recover/confirm/:email/:code" element={<PasswordRecoverConfirm />} />
      <Route path="/r/:refId" element={<Register />} />

      <Route
        path="/"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <Home />
          </ProtectedRoute>
        }
      />
      <Route path="/finance-passport-tm" element={<FinancePassportTMLandingPageView />} />
      <Route
        path="/finance-passport-tm/:referralCode"
        element={<FinancePassportTMLandingPageView />}
      />
      <Route
        path="/offers"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <OffersCategories />
          </ProtectedRoute>
        }
      />
      <Route
        path="/offers/setup"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <OffersSetup />
          </ProtectedRoute>
        }
      />
      <Route
        path="/offers/:category_id/:loan_purpose"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <Offers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/referrals"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <ReferralContextProvider>
              <Referrals />
            </ReferralContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/referrals/withdrawal"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <ReferralContextProvider>
              <ReferralsWithdrawal />
            </ReferralContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/rewards"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <Rewards />
          </ProtectedRoute>
        }
      />
      <Route
        path="/rewards/purchases"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <RewardsPurchases />
          </ProtectedRoute>
        }
      />
      <Route
        path="/rewards/brand/:brand"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <RewardDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/rewards/brand/:brand/buy"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <BuyReward />
          </ProtectedRoute>
        }
      />
      <Route
        path="/payment_landing_page"
        element={
          <ProtectedRoute>
            <PaymentOutroPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <PassportContextProvider>
              <Passport />
            </PassportContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport/:country_code"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <PassportContextProvider>
              <PassportRequestView />
            </PassportContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport/details"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <PassportDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport/request"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <PassportContextProvider>
              <PassportRequest />
            </PassportContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport/request/:country_code"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <PassportContextProvider>
              <PassportRequest />
            </PassportContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport/request/update"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <PassportContextProvider>
              <PassportRequest updatePassportMode />
            </PassportContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/passport/documents/:bankName"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <PassportContextProvider>
              <PassportDocuments />
            </PassportContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/notifications"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <Notifications />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <Settings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/remove"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SettingsRemove />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/personal-information"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SettingsPersonalInformation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/financial-information"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SettingsFinancialInformation />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/bank-details"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SettingsBankDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/bank-details/submit"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SettingsBankDetailsSubmit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/connections"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SettingsConnections />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/preferences"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SettingsPreferences />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/help"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SettingsHelp />
          </ProtectedRoute>
        }
      />
      <Route
        path="/financial-info/submit"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SubmitFinancialInfo />
          </ProtectedRoute>
        }
      />
      <Route
        path="/financial-info/submit/fixEquifaxError"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <SubmitFinancialInfo fixEquifaxError />
          </ProtectedRoute>
        }
      />
      <Route
        path="/monevo/connect"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <MonevoConnect />
          </ProtectedRoute>
        }
      />
      <Route
        path="/monevo/connect"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <MonevoConnect />
          </ProtectedRoute>
        }
      />
      <Route
        path="/monevo/connect/fix-error"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <MonevoConnect fixError />
          </ProtectedRoute>
        }
      />
      <Route
        path="/monevo/connect/:preferenceId"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <MonevoConnect />
          </ProtectedRoute>
        }
      />
      <Route
        path="/monevo/connect/:preferenceId/fix-error"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <MonevoConnect fixError />
          </ProtectedRoute>
        }
      />
      <Route
        path="/monevo/connect/:preferenceId/update"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <MonevoConnect updateApplication />
          </ProtectedRoute>
        }
      />
      <Route
        path="/abound/connect/"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <AboundConnect />
          </ProtectedRoute>
        }
      />
      <Route
        path="/abound/connect/:loanTerm"
        element={
          <ProtectedRoute requiredProfileCompleted>
            <AboundConnect />
          </ProtectedRoute>
        }
      />
      <Route
        path="/credit-score/details"
        element={
          <ProtectedRoute>
            <CreditDetailsContextProvider>
              <CreditScoreDetails />
            </CreditDetailsContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/credit-score/details/insights"
        element={
          <ProtectedRoute>
            <CreditDetailsContextProvider>
              <CreditScoreDetailsInsights />
            </CreditDetailsContextProvider>
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile/intro"
        element={
          <ProtectedRoute>
            <ProfileSetupRegisterIntroFlow />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
}

export default RoutesComponent;
